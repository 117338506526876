import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import React from 'react'

import SiteContact from '../../components/SiteContact'

class contact extends React.Component {
  render() {
    return (
      <div>
        <Helmet title={`Nous joindre | Irriglobe`} />
        <SiteContact {...this.props} />
      </div>
    )
  }
}

export default contact

export const contactPageQuery = graphql`
  query ContactPageByPath($path: String!) {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        layout
        title
        path
      }
    }
  }
`
