import React from 'react'
import './style.scss'

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Col,
  Row,
} from 'reactstrap'

import Truck from './Irriglobe_G_031.jpg'
import VisaMc from './visa_mastercard.gif'
import Carte from './carte.png'
import SEO from '../../components/seo'

const schemaOrg = `{
  "@context": "http://schema.org/",
  "@type": "Service",
  "serviceType": "Services Irrigation",
  "provider": {
    "@type": "LocalBusiness",
    "name": "Irriglobe Irrigation",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Montréal",
      "addressRegion": "QC",
      "streetAddress": "8220 pascal-gagnon"
    },
    "description": "Installation de système d'irrigation. Installation de système d'arrosage automatique. Vente et réparation. Irriglobe, le spécialiste en irrigation à Montréal, Laval, Rive-Nord, Laurentides et toutes la province de Québec!",
    "telephone": "514-905-6000",
    "image": "https://www.irriglobe.com/img/logo.png"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Montreal"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Laval"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Rive-Nord"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Laurentides"
  },
  "hasOfferCatalog": {
    "@type": "OfferCatalog",
    "name": "Services Irrigation",
    "itemListElement": [
      {
        "@type": "OfferCatalog",
        "name": "Types services irrigation",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation résidentielle"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation commerciale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation municipale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation Toîts vert"
            }
          }
        ]
      }
    ]
  }
}`

class SiteContact extends React.Component {
  render() {
    const seoData = {
      title: "Nous joindre",
      description: "Contactez-nous pour obtenir une soumission",
      coverImage: Truck,
      path: '/nous-joindre',
      schemaOrg
    }

    try {
      const head = window.document.querySelector("head");
      const script = window.document.createElement("script");

      script.setAttribute("src", 'https://webforms.pipedrive.com/f/loader');
      head.appendChild(script);
    } catch(e) {}

    return (
      <Container>
        <SEO seoData={seoData} />
        <Row><Col><h1>Contactez-nous</h1></Col></Row>
        <Row className="justify-content-center">
          <Col lg={5} md={8} xs={12}>
            <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/6q8k6rB495btDU78yx0kNsPLpaBcQDgVWh4KxEdRBGgvHm9JFO6nInREn5LWD1Nw1d">
            </div>
          </Col>
          <Col lg={7} md={4} xs={12}>
            <Card>
              <CardHeader>
                <CardTitle>Siège social</CardTitle>
              </CardHeader>
              <CardBody>
                <a href='https://www.google.com/maps/place/Irriglobe+Irrigation/@45.5994015,-73.5816116,15z/data=!4m5!3m4!1s0x0:0x5dc1c64dedf9a8da!8m2!3d45.5994015!4d-73.5816116'><img alt="Credit Card Logos" title="Credit Card Logos" src={Carte} border="0" /></a>
              </CardBody>
            </Card>
            <p>Nous desservons tout le Québec.</p>
            <p>
              Irriglobe est ouvert du lundi au vendredi de 8h30 à 16h00. Nous
              disposons d’un service d’urgence 24 heures sur 24.
            </p>
            <Row>
              <Col lg={6} md={6} xs={12}>
                <p>
                Irriglobe, siège social<br />
                8220 Pascal-Gagnon,<br />
                Montréal, QC H1P 1Y4
                </p>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <p>
                  <a href="tel:5149056000" style={{ fontSize: '2em' }}>
                    <strong>514 905-6000</strong>
                  </a><br />
                  <a href="mailto:info@irriglobe.com">info@irriglobe.com</a>
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={4} xs={6}>
                <p>
                  <img alt="Credit Card Logos" title="Credit Card Logos" src={VisaMc} border="0" />
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default SiteContact
